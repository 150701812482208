import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap"
import parse from "html-react-parser"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useLocation } from "@reach/router"
import { useContext } from "react"
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
import ModalTiny from "react-bootstrap/Modal"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import LocRatingMap from '../../map/loc-rating-map-property-details';
import BookViewForm from "../../forms/book-a-viewing-form"
import ShareFrndForm from "../../forms/send-friend-form"
import PropertyHelp from "../Help"
import PropertyAvailability from "../Property/PropertyAvailability"
import MoreDevelopments from "../Property/MoreDevelopments"
import Gallery from "../Property/Gallery"
import RegisterBlock from "../../Home/FormBlock/RegisterBlock"
import _ from "lodash"
import "./NewHomesViewPage.scss"
import "../Description/Description.scss"
import "../Payment/Payment.scss"
import "../Info/Info.scss"
import { BRANCHES_PAGE_URL } from "../../common/site/constants";

const ViewPage = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allBranches {
            nodes {
              branch_id
              sales_dept {
                intro
                phone
                manager {
                  image
                  name
                  job_title
                }
              } 
              address {
                county
                latitude
                longitude
                postcode
                street
              }
              extra {
                opening_hours_website {
                    week_day
                    timing 
                  }
              }
            }
          }
    }
    `);
    const branches = data.allBranches.nodes;
    const [isPlay, setPlay] = useState(false);
    const [colour, setColour] = useState(false);
    const [smallimgcount, setSmaillImgCount] = useState(5);

    const changeColour = () => {
        setColour(!colour);
    }
    const [show, setShow] = useState(false);
    const showMenu = () => {
        setShow(!show);


    };

    const propertyImages = props.propertyData.images
    // ggfx
    let processedImages = JSON.stringify({});
    if (props.propertyData?.Imagetransforms?.images_Transforms) {
        processedImages = props.propertyData.Imagetransforms.images_Transforms;
    }
    // ggfx



    const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
        setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
        setSendfrndformOpen(false);
    }

    const [modalSharefrndformOpen, setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    if (propertyImages.length > 0) {
        var img_1 = propertyImages[0].srcUrl
    }

    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if (Shareicons === true) {
            setShareicons(false);
        }
    }
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareurl,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    // Property details images lightbox
    var propertyLightImages = propertyImages && propertyImages.map(img => img.url);
    // Property details images lightbox


    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = props.propertyData.latitude
    mapItem['lng'] = props.propertyData.longitude
    mapItem['id'] = props.propertyData.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push(mapItem);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }

    var mappedBranch = _.find(branches, function (chr) {
        return chr.branch_id == props.propertyData.office_crm_id;
    });

    // console.log('mappedBranch', img_1)
    return (
        <React.Fragment>

            <section className="view-page">
                <Container fluid className="property-header">
                    <Container>
                        <Row>
                            <Col lg={7}>
                                <div className="property-details">
                                    <h1 className="d-none">{props.propertyData.sub_heading_2}</h1>
                                    <h2>{props.propertyData.development_title}</h2>
                                    <p className="property-sub-title">{props.propertyData.sub_heading_2}</p>
                                    {props.propertyData.min_price && props.propertyData.max_price &&
                                        <React.Fragment>
                                            {props.propertyData.min_price == props.propertyData.max_price ?
                                                <span className="property-price d-flex align-items-center">
                                                    {props.propertyData.price_prefix} £{props.propertyData.min_price.toLocaleString()}
                                                </span> :
                                                <span className="property-price d-flex align-items-center">
                                                    {props.propertyData.price_prefix} £{props.propertyData.min_price.toLocaleString()} - £{props.propertyData.max_price.toLocaleString()}
                                                </span>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="sidebar">
                                    <div className="d-lg-flex justify-content-end d-none">
                                        <a href="javascript:void(0)" className="btn btn-secondary first me-0" onClick={openSendfrndformModal}>
                                            Book a viewing
                                        </a>
                                        {
                                            props.propertyData.telephone &&
                                            <a href={`tel:${props.propertyData.telephone}`} className="btn tel upper">
                                                Call {props.propertyData.telephone}
                                            </a>
                                        }

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className="top-icons d-flex justify-content-end">
                            <div className="icons">
                                <a href="javascript:void(0)" onClick={openShareicons}>
                                    <i className="share">
                                    </i>
                                </a>
                                <span className="text" onClick={openShareicons}>Share</span>
                                {Shareicons &&
                                    <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
                                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                        </FacebookShareButton>
                                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                        </LinkedinShareButton>
                                        <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                        </WhatsappShareButton>
                                        <EmailShareButton url='#' onClick={() => { openSharefrndformModal(); trackerShare('SendToFriend') }} className="my-share-button email-share">
                                            <EmailIcon size={32} round={false} borderRadius={`10`} />
                                        </EmailShareButton>
                                    </div>
                                }
                            </div>
                        </div> */}
                    </Container>
                </Container>
                <Container>
                    <Row className="view-details video-block-wrap">

                        <Col lg={mappedBranch ? 8 : 12}>
                            <div className="description">
                                <h2>Description</h2>
                                {/* <div className={`${show ? " " : "para-content"} ${(props.propertyData.long_description).length < 350 ? 'height' : ''}`}  > */}
                                <div className={``}  >
                                        <p>
                                            {parse(props.propertyData.long_description)}
                                        </p>
                                </div>
                                {/* {(props.propertyData.long_description).length > 450 &&
                                        <div className={show ? "more-content fixed" : "more-content"} onClick={showMenu} >
                                            <span onClick={showMenu}>
                                                <i className={show ? "icon-minus-dark " : "icon-plus "} ></i>
                                                <Link className="more">
                                                    {show ? "Read Less" : "Read More"}
                                                </Link>
                                            </span>
                                        </div>
                                } */}
                            </div>
                        </Col>
                        {mappedBranch &&
                            <Col lg="4" className={`block-content block-text no-image`}>
                                <div className="guide-details-right-block main-banner">
                                    <div className="first">
                                        {mappedBranch.sales_dept &&
                                            <div className="advisor-info">
                                                <div className="advisor-img">
                                                    <img src={mappedBranch.sales_dept.manager?.image} alt={`Author`} />
                                                </div>
                                                <div className="advisor-contact default">
                                                    <span className="advisor-name">{mappedBranch.sales_dept.manager?.name}</span>
                                                    {/* <p>{mappedBranch.sales_dept.manager?.job_title}</p> */}
                                                    <a href={`tel:${mappedBranch.sales_dept.phone}`} className="phone-number">
                                                        <i className="icon-phone"></i>
                                                        <span>{mappedBranch.sales_dept.phone}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="info-text">
                                        {mappedBranch.address &&
                                            <>
                                                <div className="address-list">
                                                    <h3 className="label">Address:</h3>
                                                    <span className="list">
                                                        {/* {mappedBranch.address.building &&
                                                            <>{mappedBranch.address.building}, </>
                                                        } */}
                                                        {mappedBranch.address.street &&
                                                            <>{mappedBranch.address.street}</>
                                                        }
                                                        {mappedBranch.address.town &&
                                                            <>, {mappedBranch.address.town}</>
                                                        }
                                                        {/* {mappedBranch.address.district &&
                                                            <>, {mappedBranch.address.district}</>
                                                        } */}
                                                        {mappedBranch.address.county &&
                                                            <>, {mappedBranch.address.county}</>
                                                        }
                                                        {mappedBranch.address.postcode &&
                                                            <>, {mappedBranch.address.postcode}</>
                                                        }
                                                    </span>
                                                </div>
                                            </>

                                        }

                                    </div>
                                    <div className="info-text">
                                        {mappedBranch.extra?.opening_hours_website &&
                                            <div className="time-info order-2">
                                                <div className="office-hours">
                                                    <h3>Office Hours</h3>
                                                    <ul className="timing">
                                                        {mappedBranch.extra.opening_hours_website && mappedBranch.extra.opening_hours_website.map((item, i) => {
                                                            return (
                                                                <li>{item.week_day}:   {item.timing}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
                <hr></hr>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <PropertyAvailability
                                id={props.propertyData.development_id}
                                title={props.propertyData.development_title}
                                shareurl={shareurl}
                                image={img_1}
                            />
                            {/* <div className="view-details"> 
                                <img src={NoImage} className="image-fit-cover" alt="" />
                            </div> */}
                        </Col>
                    </Row>
                </Container>
                <Container fuild className="fuild-container">
                    <div className="locrating-map">
                        <LocRatingMap data={mapItems} />
                    </div>
                </Container>
                <Container>
                    <Gallery data={props.propertyData} />
                </Container>
                <hr></hr>
                <Container>
                    <MoreDevelopments
                        id={props.propertyData.development_id}
                        title={props.propertyData.development_title}
                        location={props.propertyData.sub_heading_2}
                        shareurl={shareurl}
                        image={img_1}
                    />
                </Container>
            </section>
            <Container>
                <Row>
                    <Col>
                        <div className='property-details-footer-content new-homes'>
                            <p>The information provided about this property does not constitute or form part of an offer or contract, nor may be it be regarded as representations. All interested parties must verify accuracy and your solicitor must verify tenure/lease information, fixtures & fittings and, where the property has been extended/converted, planning/building regulation consents. All dimensions are approximate and quoted for guidance only as are floor plans which are not to scale and their accuracy cannot be confirmed. Reference to appliances and/or services does not imply that they are necessarily in working order or fit for the purpose.</p>
                            <p>We are pleased to offer our customers a range of additional services to help them with moving home. None of these services are obligatory and you are free to use service providers of your choice. Current regulations require all estate agents to inform their customers of the fees they earn for recommending third party services. If you choose to use a service provider recommended by Pittis, details of all referral fees can be found at the link below. If you decide to use any of our services, please be assured that this will not increase the fees you pay to our service providers, which remain as quoted directly to you.</p>

                        </div>
                    </Col>
                </Row>
            </Container>
            <RegisterBlock />
            {/* Property image lightbox */}
            {
                isOpen && (
                    <Lightbox
                        mainSrc={propertyLightImages[photoIndex]}
                        nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                        prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                        }
                    />
                )
            }
            {/* Property image lightbox */}

            <div className="btn-wrapper property-details-floating-cta-fixed">
                <button className="btn btn-secondary" onClick={openSendfrndformModal}>Book a viewing</button>
                {
                    mappedBranch?.sales_dept && mappedBranch.sales_dept.phone &&
                    <a className="btn btn-primary" href={`tel:${mappedBranch.sales_dept.phone}`}>Call us</a>
                }
            </div>


            <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Get in touch</h2></ModalTiny.Title>
                </ModalTiny.Header>

                <ModalTiny.Body className="people-details-form-modal event-section">
                    <BookViewForm property_img={img_1} crm_id={props.propertyData.development_id} page_url={shareurl} property_title={props.propertyData.development_title} property_id={props.propertyData.development_id} address={props.propertyData.address} />
                </ModalTiny.Body>

            </ModalTiny>

            <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal modal-main-form modal">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">SHARE TO A FRIEND</h2></ModalTiny.Title>
                </ModalTiny.Header>

                <ModalTiny.Body className="event-section">
                    <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.development_title} property_id={props.propertyData.development_id} address={props.propertyData.address} />
                </ModalTiny.Body>

            </ModalTiny>
        </React.Fragment >
    )
}
export default ViewPage