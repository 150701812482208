import * as React from "react"
import parse from 'html-react-parser';
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, Container, Row, Col, Form } from "react-bootstrap"
import "./FormBlock.scss"

import RegisterForm from "../../forms/new-homes-register-form"
// markup
const RegisterBlock = (props) => {
    return (
        <React.Fragment>
            <section className={`book-valuation components--Home--FormBlock--FormBlock ${props.count == 1 ? 'form-main-one' : ''}`}>
                <Container>
                    <Row className="main-row">
                        <Col lg="12">
                            <div className="register-interest-form-block">
                                <div className="valuation-block">

                                    <ScrollAnimation animateIn="fadeInUp">
                                        <h2>We’ve helped thousands of people <span>just like you.</span></h2>
                                        <p>Our local agents can help your home looks its very best and reach the widest audience. We know what it takes to find the buyer that's right for you and achieve the best possible price. It all starts with a free instant valuation of your property, so find out what your home could be worth could be in 30 seconds…</p>
                                    </ScrollAnimation>

                                </div>
                                <div className="btn-row">
                                    <Link class="btn btn-secondary" to="/sell-your-property/property-valuation">Get your instant valuation<i class="icon-arrow"></i></Link>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default RegisterBlock
